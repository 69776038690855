@import "/src/assets/styles/Colors.scss";

.modal-overlay {
  position: absolute;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(2.5px);
  top: 0;
  left: 0;

  .modal-container {
    position: absolute;
    min-width: 55%;
    min-height: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: $primary-background-color;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 8px;
    padding: 16px;

    .modal-header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .modal-title {
        font-weight: bold;
        font-size: 24px;
        line-height: 1;
        color: $primary-dark-color;
      }

      .modal-close {
        cursor: pointer;
      }
    }
  }

  // for tablet view
  @media (max-width: 1000px) {
    .modal-container {
      min-width: 90%;
      min-height: 50%;
    }
  }
}
