@import "./assets/styles/Colors.scss";

body,
html {
  background-color: $primary-background-color;
  height: 100%;
}

.root {
  height: 100%;
}

.App {
  display: flex;
  flex-direction: column;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;

  a {
    text-decoration: none;
    color: #fff;
  }

  hr {
    border: 0;
    height: 1px;
    background-color: rgba(0, 0, 0, 0.13);
  }

  .main-app {
    display: flex;
  }

  // scrollbars custom
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    margin-right: 3px;
  }

  ::-webkit-scrollbar-track {
    background: $text-primary-white;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb {
    background: #bbb;
    border-radius: 8px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: $primary-dark-color;
  }
}
