@import "/src/assets/styles/Colors.scss";

.last-visited-item {
  background: #f8f8f8;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  margin: 12px 0;
  cursor: pointer;

  .last-visited-item-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2px 8px;

    .flag-icon {
      padding: 0 8px;
    }

    .last-item-content {
      padding: 8px;
      flex: 1;
      .content-title {
        font-size: 18px;
        font-weight: 500;
      }
      .content-date {
        font-weight: 400;
        font-size: 12px;
        color: $primary-dark-color;
      }
      .content-name {
        font-weight: 400;
        font-size: 15px;
        color: $primary-dark-color;
      }
    }
  }
}
