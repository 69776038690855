.user-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 15px;
  background-color: rgba(255, 255, 255, 0.1);

  .name {
    font-size: 19px;
  }

  .icons {
    display: flex;
    align-items: center;
  }
}
