@import "/src/assets/styles/Colors.scss";

.worker-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  border-bottom: 1px solid #cecece;
  padding: 8px 0;
  position: relative;

  .flag-icon img {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.22);
    width: fit-content;
    height: auto !important;
  }

  .flag-icon {
    padding: 0;
  }

  //each item of worker-item
  * {
    flex: 1;
    margin: 0 8px;
  }

  .avatar {
    flex: none !important;
  }

  .add-request {
    cursor: pointer;
    opacity: 0;
    display: flex;
    position: absolute;
    right: 0;
    top: 50%;
    background: $primary-dark-color;
    transform: translate(50%, -50%);
    border-radius: 50%;
    width: 0px;
    height: 0px;
    justify-content: center;
    align-items: center;
    color: #cecece;
    transition: all 0.15s ease-in-out;
  }
  &:hover {
    .add-request {
      opacity: 1;
      width: 35px;
      height: 35px;
    }
  }

  .firstname {
    font-weight: 700;
  }

  .position {
    font-style: italic;
  }

  .e-mail {
    font-style: italic;
    text-decoration: underline;
  }
}

.childrens {
  transition: all 0.15s ease-in-out;
  height: fit-content;
  width: 100%;
}

.childrens-collapsed {
  height: 0;
  opacity: 0;
}

.arrow {
  transition: all 0.15s ease-in-out;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translate(-75%, -50%) rotate(0deg);
  rotate: 0deg;

  .reverse {
    transform: rotate(180deg);
    transition: all 0.15s ease-in-out;
  }
}
