@import "/src/assets/styles/Colors.scss";

.avatar {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 0;
  font-size: 1.2rem;
  aspect-ratio: 1 / 1;

  .initials {
    font-weight: 600;
    margin-top: -2px;
  }
}

.avatar-dark {
  background-color: $primary-dark-color;
  color: $text-primary-white;
}

.avatar-light {
  background-color: $primary-background-color;
  color: $primary-dark-color;
}
