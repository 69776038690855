@import "/src/assets/styles/Colors.scss";

.menu-item {
  display: flex;
  justify-content: space-between;
  width: calc(100% - 3px);
  padding: 12px 0;
  cursor: pointer;

  &:hover {
    background-color: #1e232d;
  }

  .icon {
    padding: 0 8px;
    width: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .name {
    text-align: left;
    flex: 1;
  }

  .collapse {
    padding: 0 8px;
  }
}

.menu-item-active {
  background-color: #1e232d;
  border-left: 3px solid $additional-color;

  // Wyrównaj ikonę o 3px w lewo (bo border-left ma 3px) -> 8px - 3px = 5px
  .icon {
    padding-left: 5px;
  }

  .name {
    flex: 1;
  }
}

.childrens {
  .children {
    padding: 8px 35px;
    border-bottom: 1px solid #404754;
    font-size: 12px;

    &:hover {
      background-color: #1e232d;
    }
  }
}
