.top-pdf {
  display: flex;
  align-items: center;
  font-weight: bold;
  cursor: pointer;
  color: #ff0000;
  animation: pulsate 2s infinite;
}

@keyframes pulsate {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.15);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}
