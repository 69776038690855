@import "/src/assets/styles/Colors.scss";

.info-stat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  .info-desc {
    font-size: 12px;
    color: $text-secondary-white;
    white-space: pre-line;
  }

  .info-value {
    font-size: 40px;
    font-weight: 700;
    margin: 0;
    position: relative;

    .percent {
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(100%, 50%);
      font-size: 12px;
      font-weight: 700;
    }

    .success {
      color: lightgreen;
    }
  }
}
