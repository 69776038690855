.grid {
  display: flex;
  height: 100%;

  iframe {
    width: 100%;
    height: 100%;
    border: 0 !important;
  }
}
