.form-request {
  padding: 8px 16px;

  .title {
    font-weight: 400;
    font-size: 19px;
    color: #000000;
    margin-top: 0;
    padding-left: 10px;
  }

  .language-list,
  .level-list {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }

  .continuation-list,
  .level-list-2 {
    display: flex;
    flex-direction: column;

    & > * {
      flex: 1;
    }

    .level-container {
      flex: 1;
    }
  }

  .level-list {
    & > * {
      flex: 1;
    }
  }

  .first-row-list {
    display: flex;
    flex-wrap: wrap;

    & > * {
      // flex: 1;
      width: 50%;

      & > * {
        // flex: 1;
      }
    }
  }

  .second-row {
    display: flex;

    & > * {
      flex: 1;
    }
  }

  .question-row {
    display: flex;
    justify-content: space-between;

    .question-col {
      flex: 1;
      width: 50%;

      .level-list {
        justify-content: start;
      }

      .date-input {
        background: rgba(220, 225, 220, 0.2);
        border: 1px solid rgba(0, 0, 0, 0.12);
        border-radius: 8px;
        width: 100%;
        margin-top: 10px;
        height: 43px;
        font-family: inherit;
      }
    }
  }

  .textareas {
    padding: 0 26px 0 8px;
    textarea {
      width: 100%;
      height: 30px;
      background: rgba(220, 225, 220, 0.2);
      border: 1px solid rgba(0, 0, 0, 0.12);
      border-radius: 8px;
      padding: 8px;
      margin-top: 10px;
      font-family: inherit;
    }
  }

  .button-box {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  button {
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.12);
    background: #29313e;
    color: white;
    padding: 12px 24px;
    text-align: center;
    cursor: pointer;
  }
}
