@import "/src/assets/styles/Colors.scss";

.history-container {
  position: relative;
  height: 100%;

  .history {
    .timeline {
      margin-top: 20px;
      display: flex;
      justify-content: space-between;

      .timeline-container-left,
      .timeline-container-right {
        flex: 1;
        padding: 16px;
      }

      .timeline-container-left {
        border-right: 1px solid rgba(0, 0, 0, 0.16);
      }
      .timeline-container-right {
        border-left: 1px solid rgba(0, 0, 0, 0.16);
      }

      .timeline-item-content {
        background-color: #f8f8f8;
        border: 1px solid rgba(0, 0, 0, 0.08);
        border-radius: 8px;
        padding: 8px;
      }
    }
  }
}
