.level-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: rgba(220, 225, 220, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 8px 24px;
  margin: 8px;
  cursor: pointer;
  font-size: 15px;
  text-align: center;

  & > * {
    padding: 2px !important;
  }
}

.level-container-active {
  background: rgba(220, 225, 220, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.33);
  box-shadow: 0px 0px 15px rgba(0, 56, 255, 0.2);
}
