@import "/src/assets/styles/Colors.scss";
@import "/src/assets/styles/Variables.scss";

.left-menu {
  background-color: $primary-dark-color;
  width: 18%;
  height: calc(100vh - $top-menu-height);
  color: $text-primary-white;
  display: flex;
  flex-direction: column;
}
