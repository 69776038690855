.request-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 12px;
  border-bottom: 1px solid #cecece;
  padding: 8px 0;
  position: relative;
  cursor: pointer;

  .flag-icon img {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.22);
    width: fit-content;
    height: auto !important;
  }

  .flag-icon {
    flex: none;
    padding: 0;
  }

  * {
    flex: 1;
    margin: 0 8px;
  }

  .avatar {
    flex: none !important;
  }

  .e-mail {
    flex: 1.5;
  }

  .badge {
    padding: 4px;
    border-radius: 4px;
    color: white;
    font-weight: bold;
    font-size: 10px;
    text-align: center;
  }

  .number {
    font-weight: bold;
    flex: none;
  }

  .grid-admin-btn-success button {
    background-color: green;
    color: white;
    font-size: 10px;
    font-weight: bold;
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: 100%;
    cursor: pointer;
    white-space: nowrap;
  }

  .grid-admin-btn-error button {
    background-color: red;
    color: white;
    font-size: 10px;
    font-weight: bold;
    border: 0;
    padding: 4px;
    border-radius: 4px;
    width: 100%;
    margin-top: 8px;
    cursor: pointer;
    white-space: nowrap;
  }
}
