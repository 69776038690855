.new-request-modal {
  display: flex;
  width: 100%;
  padding: 12px;

  .left-side {
    flex: 3;
    border-right: 2px solid rgba(0, 0, 0, 0.15);
  }

  .right-side {
    flex: 7;
  }
}
