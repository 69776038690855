@import "/src/assets/styles/Colors.scss";
@import "/src/assets/styles/Variables.scss";
.requests {
  display: flex;
  justify-content: space-between;

  .left-content-side {
    width: 100%;
    max-height: calc(100vh - $top-menu-height - 20px);
    overflow: auto;
    margin-right: 8px;

    h2 {
      font-weight: 300;
      padding: 12px 0 24px;
      margin: 0;
      margin-left: 30px;
    }
  }

  .right-menu-widget {
    width: 25%;
  }

  .stat-item {
    margin: 0 24px 0 0;
    flex: 1;
  }

  .stat-item:first-child {
    margin-left: 8px;
  }
}
