.language-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(220, 225, 220, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 8px 12px;
  margin: 8px;
  cursor: pointer;
  min-width: 70px;
  flex: 1;
}

.active-language-container {
  background: rgba(220, 225, 220, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.33);
  box-shadow: 0px 0px 15px rgba(0, 56, 255, 0.2);
}
