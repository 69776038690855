@import "/src/assets/styles/Colors.scss";

.history-item-right {
  position: relative;

  .timeline-dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #d7d7d7;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-24px, -50%);
  }

  .item-triangle {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-9px, -50%) rotate(180deg);
  }

  .timeline-item-content {
    display: flex;
    align-items: center;
  }

  .circle-lang {
    border-radius: 50%;
    margin: 0 16px 0 8px;
    width: 40px;
    height: 40px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
