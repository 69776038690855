@import "/src/assets/styles/Colors.scss";

.toolbar {
  display: flex;
  align-items: center;

  .icon {
    padding: 0px 7px;
    position: relative;
    display: flex;
    align-items: center;
  }

  .icon:last-child {
    padding-right: 10px;
  }

  .notyfications-background {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $notyfication-background-color;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 10px;
    transform: translate(-25%, -25%);
  }
}
