.left-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(220, 225, 220, 0.2);
  border: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  padding: 8px;
  margin: 32px 24px 32px 0;
  position: relative;
  cursor: pointer;

  .item-icon {
    margin-right: 8px;
  }

  .item-informations {
    flex: 1;

    .item-title {
      font-weight: 700;
      font-size: 14px;
    }

    .item-second-line {
      font-weight: 700;
      font-size: 14px;
      color: #00bd08;
    }

    .item-date {
      font-weight: 400;
      font-size: 11px;
      line-height: 150%;
      color: #29313e;
    }
  }

  .timeline-dot {
    width: 14px;
    height: 14px;
    border-radius: 50%;
    background-color: #d7d7d7;
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(33px, -50%);
  }

  .item-triangle {
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(10px, -50%);
  }
}

.left-item-disabled {
  opacity: 0.4;
}
