@import "/src/assets/styles/Colors.scss";
@import "/src/assets/styles/Variables.scss";

.top-menu {
  background-color: $white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.05);
  height: $top-menu-height;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
