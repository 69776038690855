.box-container {
  background: #ffffff;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 15px;

  .title {
    font-size: 20px;
    font-weight: 500;
    margin: 0;
  }
}
